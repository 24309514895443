import { Transition } from '@uirouter/angular';

import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { LanguageCodes } from 'rev-shared/util/LanguageCodes.Service';
import { LicenseService } from 'rev-shared/security/License.Service';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { PORTAL_USER_PROFILE } from 'rev-shared/bootstrap/AppStateNames';

import { UserProfileService } from './UserProfile.Service';
import { UserProfileComponent } from './UserProfile.Component';

export const states: IVbNg2StateDeclaration[] = [{
	name: PORTAL_USER_PROFILE,
	url: '/user-profile',
	component: UserProfileComponent,
	resolve: [
		{
			token: 'user',
			deps: [UserProfileService],
			resolveFn(UserProfileService: UserProfileService) {
				return UserProfileService.getUserDetail();
			}
		},
		{
			token: 'languageCodes',
			deps: [LanguageCodes],
			resolveFn(LanguageCodes: LanguageCodes) {
				return LanguageCodes.load();
			}
		},
		{
			token: 'backTransition',
			deps: [Transition],
			resolveFn($transition$: Transition) {

				return {
					name: $transition$.from().name,
					params: $transition$.params('from')
				};
			},
		},
		{
			token: 'accountLicense',
			deps: ['accountId', LicenseService],
			resolveFn(accountId: string, LicenseService: LicenseService) {
				return LicenseService.getLicense(accountId);
			}
		},
		{
			token: 'features',
			deps: ['accountId', MediaFeaturesService],
			resolveFn(accountId: string, MediaFeatures: MediaFeaturesService) {
				return MediaFeatures.getFeatures(accountId);
			}
		}
	]
}];
