import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { formatTimespan } from 'rev-shared/date/DateFormatters';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { PushService } from 'rev-shared/push/PushService';

import { UserAccountService } from 'rev-shared/security/UserAccount.Service';
import { CacheFactory } from 'rev-shared/util/CacheFactory';

@Injectable({
	providedIn: 'root'
})

export class SecuritySettingsService {
	private readonly securitySettingsCache: CacheFactory<any>;

	constructor(
		private readonly http: HttpClient,
		private readonly PushService: PushService,
		private readonly MediaFeatures: MediaFeaturesService,
		private readonly DateParsers: DateParsersService,
		private readonly UserAccount: UserAccountService
	) {
		this.securitySettingsCache = new CacheFactory<any>(3);
	}

	public getSettings(forceReload?: boolean, accountId?: string): Promise<any> {
		accountId = accountId || this.UserAccount.workingAccountId;
		const cachedFeatures = this.securitySettingsCache.get(accountId);

		return cachedFeatures && !forceReload
			? Promise.resolve(cachedFeatures)
			: this.getSecuritySettings(accountId);
	}

	private getSecuritySettings(accountId: string): Promise<any> {
		return lastValueFrom(this.http.get(`/network/accounts/${accountId}/user-security`))
			.then((result: any) => {
				const securitySettings: any = result.securitySettings;
				const passwordRules: any = securitySettings.passwordRules || {};
				const mfaSettings: any = securitySettings.mfaSettings || {};
				const lockoutSettings: any = securitySettings.lockoutSettings || {};
				const mobileAppSettings: any = securitySettings.mobileAppSettings || {};
				const userProvisioningEnabled: boolean = result.userProvisioningEnabled;
				const ssoCertificateExpiration: any = result.ssoCertificateExpiration;

				Object.assign(securitySettings, {
					passwordRules: {
						minimumLength: passwordRules.minimumLength,
						requireLowercaseLetter: !!passwordRules.minimumLowercaseLetterCount,
						requireUppercaseLetter: !!passwordRules.minimumUppercaseLetterCount,
						requireNumber: !!passwordRules.minimumNumberCount,
						requireSpecialCharacter: !!passwordRules.minimumSpecialCharacterCount,
						passwordExpiry: passwordRules.passwordExpiry
					},
					mfaSettings: {
						requireMfa: !!mfaSettings.requireMfa
					},
					lockoutSettings: {
						maximumLogOnAttempts: lockoutSettings.maximumLogOnAttempts,
						enableForgotPassword: !!lockoutSettings.enableForgotPassword,
						lockoutPeriod: this.DateParsers.parseTimespan(lockoutSettings.lockoutPeriod),
						invalidAttemptPeriod: this.DateParsers.parseTimespan(lockoutSettings.invalidAttemptPeriod)
					},
					mobileAppSettings: {
						enableMobileAppAccess: mobileAppSettings.enableMobileAppAccess,
						inActivityTimeOutDays: mobileAppSettings.inActivityTimeOutDays,
						intuneEnabled: mobileAppSettings.intuneEnabled
					},
					sessionTimeout: this.DateParsers.parseTimespan(securitySettings.sessionTimeout),
					userProvisioningEnabled,
					ssoCertificateExpiration,
					enableEmailToSuspendedUsers: securitySettings.enableEmailToSuspendedUsers,
					enableContinuousLoginMessage: securitySettings.enableContinuousLoginMessage,
					enableAzureAdScim: result.enableAzureAdScim
				});

				this.securitySettingsCache.put(accountId, securitySettings);
				return securitySettings;
			});
	}

	public saveSecuritySettings(securitySettings: any): Promise<void> {
		const passwordRules: any = securitySettings.passwordRules;
		const mfaSettings: any = securitySettings.mfaSettings;
		const lockoutSettings: any = securitySettings.lockoutSettings;
		const mobileAppSettings: any = securitySettings.mobileAppSettings;
		const scimSettings = securitySettings.scimSettings;

		if (scimSettings && !scimSettings.isEnabled) {
			scimSettings.dateCreated = null;
			scimSettings.expiryDate = null;
			scimSettings.url = null;
			scimSettings.token = null;
		}

		return this.PushService.dispatchCommand('network:SaveSecuritySettings', {
			accountId: securitySettings.accountId,
			passwordRules: {
				minimumLength: passwordRules.minimumLength,
				minimumLowercaseLetterCount: passwordRules.requireLowercaseLetter ? 1 : 0,
				minimumUppercaseLetterCount: passwordRules.requireUppercaseLetter ? 1 : 0,
				minimumNumberCount: passwordRules.requireNumber ? 1 : 0,
				minimumSpecialCharacterCount: passwordRules.requireSpecialCharacter ? 1 : 0,
				passwordExpiry: passwordRules.passwordExpiry
			},
			mfaSettings: {
				requireMfa: mfaSettings.requireMfa
			},
			lockoutSettings: {
				maximumLogOnAttempts: lockoutSettings.maximumLogOnAttempts,
				enableForgotPassword: lockoutSettings.enableForgotPassword,
				lockoutPeriod: formatTimespan(lockoutSettings.lockoutPeriod),
				invalidAttemptPeriod: formatTimespan(lockoutSettings.invalidAttemptPeriod)
			},
			mobileAppSettings: {
				enableMobileAppAccess: mobileAppSettings.enableMobileAppAccess,
				inActivityTimeOutDays: mobileAppSettings.inActivityTimeOutDays,
				intuneEnabled: mobileAppSettings.intuneEnabled
			},
			sessionTimeout: formatTimespan(securitySettings.sessionTimeout),
			enableJwtAuth: securitySettings.jwtSettings.enabled,
			ssoSettings: securitySettings.ssoSettings,
			userLocation: securitySettings.userLocation,
			systemLoginMessages: securitySettings.systemLoginMessages,
			enableEmailToSuspendedUsers: securitySettings.enableEmailToSuspendedUsers,
			enableContinuousLoginMessage: securitySettings.enableContinuousLoginMessage,
			scimSettings
		})
			.then(() => {
				this.MediaFeatures.clearCache(securitySettings.accountId);
				this.securitySettingsCache.remove(securitySettings.accountId);
			});
	}

	public regenerateSsoCert(accountId: string): Promise<any> {
		return lastValueFrom(this.http.post(`/network/accounts/${accountId}/sso/cert`, null));
	}
	public resetSystemLoginAcceptance(accountId: string): Promise<void> {
		return this.PushService.dispatchCommand('network:ResetSystemLoginAcceptance', { accountId });
	}

	public addJwtCertificate(accountId: string, name: string, signingCertificate: string, requireJwtEncryption: boolean, autoGenerateSigningCert: boolean) {
		const disableJwtEncryption = !requireJwtEncryption;
		return this.PushService.dispatchCommand('network:AddJwtCertificate', { accountId, name, signingCertificate, disableJwtEncryption, autoGenerateSigningCert });
	}

	public removeJwtCertificate(accountId: string, name: string) {
		return this.PushService.dispatchCommand('network:RemoveJwtCertificate', { accountId, name });
	}

	public getMsTeamsJson(accountId: string, jwtCertificateName: string) {
		return lastValueFrom(this.http.get(`/network/accounts/${accountId}/user-security/ms-teams-json/${jwtCertificateName || null}`));
	}

	public generateScimToken(accountId: string): Promise<any> {
		return lastValueFrom(this.http.get(`/network/accounts/${accountId}/scim/token`));
	}
}
