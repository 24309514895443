import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';

import { getDaysUntilExpiration } from 'rev-portal/admin/userAccess/user/User.Service';

import { AuthenticationSourceType } from 'rev-shared/security/AuthenticationSourceType';
import { BaseControlValueAndValidationAccessorComponent } from 'rev-shared/util/directives/form/validation/BaseControlValueAndValidationAccessor.Component';
import { FileUtil } from 'rev-shared/util/FileUtil';
import { IAccountLicense } from 'rev-shared/security/IAccountLicense';
import { IImageComponentMetadata, IImageData } from 'rev-shared/ui/image-selector/ImageSelector.Contract';
import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';
import { INamedConstant } from 'rev-shared/constants/INamedConstant';
import { LanguageCodes } from 'rev-shared/util/LanguageCodes.Service';

import styles from './UserProfile.module.less';

const MaxProfileSize = 5 * FileUtil.Mb;

@Component({
	selector: 'user-profile-basic-settings',
	templateUrl: './UserProfileBasicSettings.Component.html',
	host: {
		'class': 'theme-primary box-block'
	},
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => UserProfileBasicSettingsComponent),
		multi: true
	},
	{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => UserProfileBasicSettingsComponent),
		multi: true
	}]
})
export class UserProfileBasicSettingsComponent extends BaseControlValueAndValidationAccessorComponent<any> implements OnInit, ControlValueAccessor {
	@Input() private accountLicense: IAccountLicense;
	@Input() public features: IMediaFeatures;
	@Input() public user: any;
	@Output() public imageData = new EventEmitter<IImageData>();
	@ViewChild('email') public email: NgModel;

	public readonly styles = styles;
	public readonly languageCodes: INamedConstant[] = this.LanguageCodes.languageCodes;
	public imageCfgBasic: IImageComponentMetadata;
	public imageDataBasic: IImageData;
	public daysUntilExpiration: number;

	public saving: boolean;

	public get isLdapUser(): boolean {
		return this.value && this.value.sourceType == AuthenticationSourceType.LDAP;
	}

	constructor(
		private readonly LanguageCodes: LanguageCodes
	) {
		super();
	}

	public ngOnInit(): void {
		if (this.user.sourceType === AuthenticationSourceType.System && this.user.passwordExpiry) {
			this.daysUntilExpiration = getDaysUntilExpiration(this.user.lastPasswordChange, this.user.passwordExpiry);
		}
	}

	public writeValue(data: any): void {
		super.writeValue(data);

		if(this.value) {
			this.setInitValue();
		}
	}

	public showFacialRecognitionOptOut(): boolean {
		return this.value.profileImageId && this.accountLicense.aiCreditsValid && this.features.enableFacialRecognition;
	}

	public onImageChanged(imageData): void {
		this.imageData.emit(imageData);
	}

	private setInitValue(): void {
		this.imageDataBasic = {
			url: this.value.profileImageUri
		};
		this.imageCfgBasic = {
			requiredValidation: false,
			showDeleteButton: true,
			readOnly: this.value.isLdapProfileImage,
			sizeValidation: true,
			maxSize: MaxProfileSize
		};
		this.imageData.emit(this.imageDataBasic);
	}
}
