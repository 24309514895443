<section [ngClass]="styles.columnContainer" ngForm="vbUserForm" #vbUserForm="ngForm">
	@if (value) {
		<section class="theme-primary-border" flex="50" [ngClass]="styles.columnLeft">

			<vb-ui-form-group>
				<label #uiLabel>
					{{ value.username }}
				</label>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="firstName" #uiLabel>
					{{ 'FirstName' | translate }}
				</label>
				<input type="text" name="firstName" id="firstName" [(ngModel)]="value.firstName" [disabled]="isLdapUser" uiControl vbUiTextInput trim="blur"/>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="lastName" #uiLabel>
					{{ 'LastName' | translate }}
				</label>
				<input #lastName="ngModel" type="text" name="lastName" id="lastName" vbRequired [(ngModel)]="value.lastName" [disabled]="isLdapUser" uiControl vbUiTextInput trim="blur"/>
				<div uiValidation>
					@if (lastName.invalid) {
						<div role="alert" class="margin-bottom-10" vbUiErrorMessage>
							@if (lastName.errors.required) {
								<span>{{ 'ThisFieldIsRequired' | translate }}</span>
							}
						</div>
					}
				</div>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="email" #uiLabel>
					{{ 'ContactEmail' | translate }}
				</label>
				<input #email="ngModel" type="text" name="email" id="email" [(ngModel)]="value.email" [disabled]="isLdapUser" [vbClearOnChange]="'emailInUse'" vbEmail uiControl vbUiTextInput trim="blur"/>
				<div uiValidation>
					@if (email.invalid) {
						<div role="alert" class="margin-bottom-10" vbUiErrorMessage>
							@if (email.errors.emailInUse) {
								<span>{{ 'EmailInUse' | translate }}</span>
							}
							@if (email.errors.vbEmail) {
								<span>{{ 'InvalidEmailError' | translate }}</span>
							}
						</div>
					}
				</div>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="profileTitle" #uiLabel>
					{{ 'ContactInfo_Title' | translate }}
				</label>
				<input type="text" name="title" id="profileTitle" [(ngModel)]="value.title" [disabled]="isLdapUser" uiControl vbUiTextInput trim="blur"/>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="phone" #uiLabel>
					{{ 'PhoneNumber' | translate }}
				</label>
				<input type="text" name="phone" id="phone" [(ngModel)]="value.phone" [disabled]="isLdapUser" uiControl vbUiTextInput trim="blur"/>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="language" #uiLabel>
					{{ 'PreferredLanguage' | translate }}
				</label>
				<vb-ui-select uiControl>
					<select #selectElement [(ngModel)]="value.language" id="language" name="language">
						<option value="">{{ 'None' | translate }}</option>
						@for (language of languageCodes; track $index) {
							<option [value]="language.code">{{ language.name }}</option>
						}
					</select>
				</vb-ui-select>
			</vb-ui-form-group>

		</section>

		<!-- Right Hand Section -->
		<section flex="50" [ngClass]="styles.columnRight">
			<vb-ui-form-group class="padding-bottom-10">
				<label for="password" #uiLabel [ngClass]="styles.passwordLabel">
					{{ 'Password' | translate }}
				</label>
				<div uiControl>
					@if (user.passwordExpiry) {
						<div class="margin-bottom-5">{{ 'PasswordExpiresInDays_Msg' | translate : { '0' : daysUntilExpiration } }}</div>
					}
					<div *vbAuthorizationKey="'resetPassword'">
						<a class="theme-primary-txt" [uiSref]="'initiate-password-reset'" [uiParams]="{ isChangePassword: true, prefillUsername: value.username }">
							{{ 'Password_Change_Msg' | translate }}
						</a>
					</div>
				</div>
			</vb-ui-form-group>
			@if (value.isProfileImageProcessing) {
				<vb-ui-form-group>
					<label for="language" #uiLabel>
						{{ 'UserProfile_ProfilePicture' | translate }}
					</label>
					<div uiControl [ngClass]="styles.profileInProgress">
						<vb-loading-spinner uiControl [size]="'small'">
							</vb-loading-spinner>
						<alert type="warning" class="width-full">
							{{ 'UserProfile_ProfilePictureUploadInProgress' | translate }}
						</alert>
					</div>
				</vb-ui-form-group>
			} @else {
				<image-selector #imageModel="ngModel" name="profileImage" [ngClass]="styles.profilePicture" (ngModelChange)="onImageChanged($event)" [imageComponentMetadata]="imageCfgBasic" [(ngModel)]="imageDataBasic" [disabled]="imageCfgBasic.readOnly" [label]="'UserProfile_ProfilePicture' | translate">
				</image-selector>
			}

			@if (showFacialRecognitionOptOut()) {
				<vb-ui-form-group>
					<vb-ui-checkbox name="disableFacialRecognition" [(ngModel)]="value.disableFacialRecognition" uiControl>
						{{ 'UserProfile_DisableFacialRecognition' | translate }}
					</vb-ui-checkbox>
				</vb-ui-form-group>
			}

		</section>
	}
</section>
