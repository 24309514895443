<vb-title [title]="'UserProfile' | translate"></vb-title>
<form #form="ngForm" novalidate [ngClass]="styles.form" [hidden]="!user || saving" (submit)="submit()">

	<vb-ui-accent-header [headerText]="'UserProfile_MyProfile' | translate" headerWidth="35%" headerMobileWidth="50%" [hasActions]="true">
		<button type="button" (click)="close()" vbBtnSecondary>
			{{ 'Cancel' | translate }}
		</button>
		<button type="submit" class="margin-left-5" [disabled]="form.invalid || user.isProfileImageProcessing" vbBtnPrimary>
			{{ 'Save' | translate }}
		</button>
	</vb-ui-accent-header>

	<vb-ui-tab-panel [ngClass]="styles.tabsWrapper" flex="fill" [verticalFill]="true" [themed]="true">
		<vb-ui-tab #basicSettingsTab [isInvalid]="basicSettingsModel?.invalid" heading="{{ 'UserProfile_HeaderBasic' | translate }}">
			<div [ngClass]="styles.tabContentsContainer">
				<user-profile-basic-settings [accountLicense]="accountLicense" (imageData)="handleImage($event)" name="userBasicSettings" [(ngModel)]="userBasicSettings" #basicSettingsModel="ngModel" [features]="features" [user]="user">
				</user-profile-basic-settings>
			</div>
		</vb-ui-tab>
		<vb-ui-tab *ngIf="features.mfaEnabled" #securitySettingsTab heading="{{ 'UserProfile_HeaderSecurity' | translate }}">
			<div [ngClass]="styles.tabContentsContainer">
			</div>
		</vb-ui-tab>
	</vb-ui-tab-panel>

	<div class="margin-bottom-10 margin-top-10 text-align-right">
		<button type="button" (click)="close()" vbBtnSecondary>
			{{ 'Cancel' | translate }}
		</button>

		<button type="submit" class="margin-left-5" [disabled]="form.invalid || user.isProfileImageProcessing" vbBtnPrimary>
			{{ 'Save' | translate }}
		</button>
	</div>
</form>

<vb-loading-spinner [ngClass]="styles.loadingSpinner" flex="fill" flex-align="center" layout="row" [size]="'large'" [hidden]="!(!user || saving)">
</vb-loading-spinner>


