import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { PushService } from 'rev-shared/push/PushService';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

@Injectable()
export class UserProfileService {
	constructor(
		private readonly http: HttpClient,
		private readonly PushBus: PushBus,
		private readonly PushService: PushService,
		private readonly UserContext: UserContextService
	) { }

	public getUserDetail(): Promise<any> {
		const userId = this.UserContext.getUser().id;
		if(!userId){
			throw new Error('User is not logged in');
		}

		return lastValueFrom(this.http.get(`/network/users/${userId}`));
	}

	public saveProfile(user: any, profilePhoto: FileWrapper): Promise<any> {
		return this.trySaveProfilePhoto(user, profilePhoto)
			.then(() => this.sendUpdateCommand(user))
			.then(commandResult => commandResult.unsubscribePromise);
	}

	private sendUpdateCommand(user: any): Promise<any> {
		return this.PushService.dispatchCommand('network:UpdateUserProfile', {
			userId: user.id,
			firstName: user.firstName,
			lastName: user.lastName,
			email: user.email,
			title: user.title,
			phone: user.phone,
			language: user.language,
			profileImageId: user.profileImageId,
			disableFacialRecognition: user.disableFacialRecognition
		});
	}

	private trySaveProfilePhoto(user: any, profilePhoto: FileWrapper): Promise<void> {
		if(!profilePhoto) {
			return Promise.resolve();
		}
		return this.getNewImageId(user.id)
			.then(uploadInfo =>
				this.uploadFile(profilePhoto, uploadInfo, 'ImageUploadingFinished', 'ImageStoringFailed')
			)
			.then(imageId => { user.profileImageId = imageId; });
	}

	private getNewImageId(userId: string): Promise<{ id: string; uploadUri: string }> {
		return this.PushService.dispatchCommand('network:AddUserProfileImage',
			{ userId },
			'ImageCreated')
			.then(result => ({
				id: result.message.imageId,
				uploadUri: result.message.uploadUri
			}));
	}

	private uploadFile(
		file: FileWrapper,
		uploadDetails: {id: string; uploadUri: string},
		finishedEvent: string,
		failedEvent: string
	): Promise<string> {

		file.setOptions({
			url: uploadDetails.uploadUri
		});

		const UploadStatusRouteScope = 'UploadStatus';

		const storingFinished = this.PushBus.awaitMessage({
			route: uploadDetails.id,
			routeScope: UploadStatusRouteScope,
			events: finishedEvent,
			rejectEvents: failedEvent
		});
		return storingFinished.subscribed
			.then(() => file.submit())
			.then(() => storingFinished)
			.then(() => uploadDetails.id);
	}
}
