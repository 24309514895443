export enum UserStatus {
	Active = 'Active',
	Suspended = 'Suspended',
	PasswordExpired = 'PasswordExpired',
	Unlicensed = 'Unlicensed',
	AwaitingConfirmation = 'AwaitingConfirmation',
	LockedOut = 'LockedOut',
	AwaitingPasswordReset = 'AwaitingPasswordReset',
	AwaitingSecurityQuestionReset = 'AwaitingSecurityQuestionReset'
}
