import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthenticationSourceType } from 'rev-shared/security/AuthenticationSourceType';
import { DayMs } from 'rev-shared/date/Time.Constant';
import { PushService } from 'rev-shared/push/PushService';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { SecuritySettingsService } from 'rev-shared/security/SecuritySettings.Service';
import { UserAccountService } from 'rev-shared/security/UserAccount.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { isNumber } from 'rev-shared/util';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

import { SearchConstants } from 'rev-portal/search/SearchConstants';
import { SearchService } from 'rev-portal/search/Search.Service';

import { UserStatus } from './UserStatus';

export function getDaysUntilExpiration(lastPasswordChange: Date, passwordExpiry: number): number {
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	const lastChangeDate = new Date(lastPasswordChange);
	const expiryDate = new Date(lastChangeDate);
	expiryDate.setDate(lastChangeDate.getDate() + passwordExpiry);

	const timeDiff = expiryDate.getTime() - today.getTime();
	const daysDiff = Math.ceil(timeDiff / DayMs);

	return daysDiff;
}

@Injectable({
	providedIn: 'root'
})
export class UserService {
	constructor(
		private readonly http: HttpClient,
		private readonly PushService: PushService,
		private readonly SearchService: SearchService,
		private readonly SecurityContext: SecurityContextService,
		private readonly UserContext: UserContextService,
		private readonly UserAccount: UserAccountService,
		private readonly SecuritySettingsService: SecuritySettingsService
	) { }

	/**
	 * Returns a page of users within an account.
	 *
	 * Parameters:
	 *   opts
	 *   - accountId (required)
	 *   - query
	 *   - groupIds
	 *   - sortField (defaults to Username)
	 *   - sortAscending (defaults to true)
	 *   - scrollId (defaults to *)
	 **/
	public getPage(opts: any): Promise<any> {
		const count = opts.count || this.SearchService.initialPageSize;

		return this.SecuritySettingsService.getSettings()
			.then(securitySettings => {
				return this.SearchService.queryAccessEntities({
					accountId: opts.accountId,
					type: SearchConstants.accessEntityTypes.user,
					query: opts.query,
					groupIds: opts.ids,
					sortField: opts.sortField || SearchConstants.nameSortField,
					sortAscending: opts.sortAscending !== false,
					count,
					scrollId: opts.scrollId,
					noScroll: opts.noScroll,
					fl: opts.fl
				}).then(result => {
					result.accessEntities.forEach(entity => this.setOtherDetails(entity, securitySettings));
					return {
						users: result.accessEntities,
						totalUsers: result.totalHits,
						lastPage: result.accessEntities.length < count,
						scrollId: result.scrollId
					};
				});
			});
	}

	/**
	 * Loads all users within an account into the destination array.
	 * If the scrollId option is provided, loads all remaining records starting at the mark.
	 *
	 * Returns a promise that is fulfilled when all users have loaded.
	 *
	 * Parameters:
	 *   userList -- destination array
	 *   opts
	 *   - All UserService#getPage options
	 *   - destinationArrayOffset (optional, loads users into a sparse destination array at offset
	 **/
	public getAll(userList: any[], opts: any): Promise<any> {
		return new Promise((resolve, reject) => {
			return getRemainingUsers(opts);

			function getRemainingUsers(opts) {
				return this
					.getPage(opts)
					.then(result => {
						//todo fix new user functionality
						if (isNumber(opts.destinationArrayOffset)) {
							result.users.forEach((user, index) => {
								userList[opts.destinationArrayOffset + index] = user;
							});
						} else {
							Array.prototype.push.apply(userList, result.users);
						}

						if (result.users.length < (opts.count || this.SearchService.initialPageSize)) {
							resolve(userList);
						} else {
							const offset = opts.destinationArrayOffset ? opts.destinationArrayOffset + result.users.length : null;
							getRemainingUsers(Object.assign(opts, {
								count: this.SearchService.pageSize,
								scrollId: result.scrollId,
								destinationArrayOffset: offset
							}));
						}
					})
					.catch(err => reject(err));
			}
		});
	}

	public getUserDetail(userId: string): Promise<any> {
		return this.SecuritySettingsService.getSettings()
			.then(settings => {
				return lastValueFrom(this.http.get<any>(`/network/users/${userId}`))
					.then(user => {
						Object.assign(user, {
							id: userId,
							userStatus: user.status,
							address: user.address || {},
							roleIds: user.roleIds || [],
							groupIds: user.groupIds || []
						});
						this.setOtherDetails(user, settings);
						return user;
					});
			});
	}

	public getConfirmationToken(userId: string): Promise<string> {
		return lastValueFrom(this.http.get<any>(`/network/users/${userId}/confirmation`))
			.then(data => data.token);
	}

	public getPasswordResetToken(userId: string): Promise<string> {
		return lastValueFrom(this.http.get<any>(`/network/users/${userId}/reset-token`))
			.then(data => data.token);
	}

	public create(user: any): Promise<void> {
		const address = user.address || {};
		return this.PushService.dispatchCommand('network:RegisterUser', {
			accountId: user.accountId,
			username: user.username,
			email: user.email,
			firstName: user.firstName,
			lastName: user.lastName,
			title: user.title,
			phone: user.phone,
			address: {
				line1: address.line1,
				line2: address.line2,
				city: address.city,
				state: address.state,
				countryCode: address.countryCode,
				postalCode: address.postalCode
			},
			language: user.language,
			roleIds: user.roleIds,
			groupIds: user.groupIds
		}, ['UserCreated'])
			.then(result => result.message);
	}

	public modify(user: any): Promise<void> {
		const address = user.address || {};
		return this.PushService.dispatchCommand('network:SaveUserDetails', {
			userId: user.id,
			accountId: user.accountId,
			email: user.email,
			firstName: user.firstName,
			lastName: user.lastName,
			title: user.title,
			phone: user.phone,
			address: {
				line1: address.line1,
				line2: address.line2,
				city: address.city,
				state: address.state,
				countryCode: address.countryCode,
				postalCode: address.postalCode
			},
			language: user.language,
			roleIds: user.roleIds,
			groupIds: user.groupIds
		})
			.then(commandResult => commandResult.unsubscribePromise);
	}

	public suspend(accountId: string, userId: string): Promise<void> {
		return this.PushService.dispatchCommand('network:SuspendUser', {
			accountId,
			userId
		});
	}

	public unsuspend(accountId: string, userId: string): Promise<any> {
		return this.PushService.dispatchCommand('network:UnsuspendUser', {
			accountId,
			userId
		}, ['AcquireUserLicenseFailed', 'UserUnsuspended']);
	}

	public unlock(accountId: string, userId: string): Promise<void> {
		return this.PushService.dispatchCommand('network:UnlockUser', {
			accountId,
			userId
		});
	}

	public requestPasswordReset(accountId: string, username: string): Promise<void> {
		return this.PushService.dispatchCommand('network:AdminRequestPasswordReset', {
			accountId,
			username
		});
	}

	public reset(accountId: string, userId: string): Promise<void> {
		return this.PushService.dispatchCommand('network:ResetUser', {
			accountId,
			userId
		});
	}

	public delete(accountId: string, userId: string): Promise<void> {
		return this.PushService.dispatchCommand('network:DeleteUser', {
			accountId,
			userId
		});
	}

	public resendConfirmationEmail(accountId: string, userId: string, username: string): Promise<void> {
		return this.PushService.dispatchCommand('network:ResendUserConfirmation', {
			accountId,
			userId,
			username
		});
	}

	public generateApiKey(accountId: string, userId: string): Promise<any> {
		return this.PushService.dispatchCommand('network:GenerateApiKey', {
			accountId,
			userId
		});
	}

	public deleteApiKey(userId: string): Promise<any> {
		return this.PushService.dispatchCommand('network:DeleteApiClientKey', {
			userId
		});
	}

	private setOtherDetails(user: any, securitySettings: any): void {
		this.setStatus(user, securitySettings);
		this.expandDataPoints(user, securitySettings);
	}

	private setStatus(user: any, securitySettings: any) {
		if (!user.userStatus) { return; }

		const passwordExpiry = securitySettings.passwordRules.passwordExpiry;

		const isUserExpired = user.lastPasswordChange
			&& passwordExpiry
			&& user.sourceType === AuthenticationSourceType.System
			&& user.userStatus !== UserStatus.Unlicensed
			&& user.userStatus !== UserStatus.AwaitingSecurityQuestionReset
			&& user.userStatus !== UserStatus.AwaitingConfirmation
			&& getDaysUntilExpiration(user.lastPasswordChange, passwordExpiry) <= 0;

		if (user.itemStatus === UserStatus.Suspended) {
			user.status = UserStatus.Suspended;
		} else if (isUserExpired) {
			user.status = UserStatus.PasswordExpired;
		} else {
			switch (user.userStatus) {
				case UserStatus.Unlicensed:
				case UserStatus.AwaitingConfirmation:
				case UserStatus.AwaitingPasswordReset:
				case UserStatus.AwaitingSecurityQuestionReset:
				case UserStatus.LockedOut:
				case UserStatus.Active:
					user.status = user.userStatus;
					break;
			}
		}
		this.setUserContextActionPermission(user);
	}

	private expandDataPoints(user: any, securitySettings: any): void {
		user.name = ([user.firstName, user.lastName].filter(Boolean)).join(' ');
		if (user.lastPasswordChange && securitySettings.passwordRules.passwordExpiry && user.sourceType === AuthenticationSourceType.System) {
			user.daysUntilExpiration = getDaysUntilExpiration(user.lastPasswordChange, securitySettings.passwordRules.passwordExpiry);
		}
	}

	public uploadUsers(userUploadData: any): Promise<any> {
		return this.PushService.dispatchCommand('network:AddAccountUsersUpload',
			{
				accountId: this.UserAccount.workingAccountId,
				filename: userUploadData.file.name,
				createGroup: userUploadData.createGroup,
				groupOption: userUploadData.groupOption
			}, ['AccountUsersUploadAdded'])
			.then(result => {
				return {
					id: result.message.uploadId,
					//TODO: build from the server...
					uploadUri: '/uploads/users/' + this.UserAccount.workingAccountId + '/' + result.message.uploadId
				};
			});
	}

	public getUsersUploadStatus(accountId: string): Promise<void> {
		return lastValueFrom(this.http.get<any>(`/network/accounts/${accountId}/users-upload`));
	}

	public initiateUsersDownload(accountId: string): Promise<void> {
		return lastValueFrom(this.http.post<any>(`/network/accounts/${accountId}/users/csv`, {}));
	}

	private setUserContextActionPermission(user: any): void {
		user.allowReset = ((user.userStatus === UserStatus.AwaitingSecurityQuestionReset) && this.SecurityContext.checkAuthorization('admin.users.edit'));

		user.allowUnsuspend =
			((user.itemStatus === UserStatus.Suspended) && this.SecurityContext.checkAuthorization('admin.users.edit')) &&
			((user.sourceType !== AuthenticationSourceType.LDAP) || (user.sourceType === AuthenticationSourceType.LDAP && !user.sourceRemoved));

		user.allowUnlock = ((user.userStatus === UserStatus.LockedOut) && this.SecurityContext.checkAuthorization('admin.users.unlock'));

		user.allowSuspend = ((user.id !== this.UserContext.getUser().id) &&
			!user.isRootUser &&
			(user.itemStatus === UserStatus.Active) &&
			this.SecurityContext.checkAuthorization('admin.users.suspend')) &&
			((user.sourceType !== AuthenticationSourceType.LDAP) || (user.sourceType === AuthenticationSourceType.LDAP && !user.sourceRemoved));

		user.allowDelete = ((user.id !== this.UserContext.getUser().id) &&
			!user.isRootUser &&
			this.SecurityContext.checkAuthorization('admin.users.delete'));

		user.enableActions = user.allowReset || user.allowUnsuspend || user.allowUnlock || user.allowSuspend || user.allowDelete;
	}
}
